import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  usersPostChangePlan,
  usersPostChangePlanDallE,
} from "../../../libs/api/users/stripe";
import ReactLoading from "react-loading";
import { User } from "../../../interfaces/user";
import { Plan } from "../../../interfaces/plan";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";

type Props = {
  plan: Plan;
  title: string;
  message?: string;
  open: boolean;
  setOpen: (bool: boolean) => void;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  user: User;
  cta?: string;
  isDallE?: boolean;
};

const ChangePlanConfirm: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [termsText, setTermsText] = useState("");

  useEffect(() => {
    // ここで'/path/to/terms.txt'は、実際のテキストファイルへのパスに置き換えてください。
    // このファイルはpublicフォルダ内に置くことで、アプリケーションのルートからアクセスできます。
    fetch("/terms.txt")
      .then((response) => response.text())
      .then((text) => {
        setTermsText(text);
      });
  }, []);

  const handleClickPlanChangeBtn = async () => {
    if (props.isDallE) {
      setLoading(true);
      await usersPostChangePlanDallE(props.plan.id);
      setLoading(false);
      props.setOpen(false);
      location.href = "/users/plans/redirect_after_plan_dall_e_change";
    } else {
      setLoading(true);
      await usersPostChangePlan(props.plan.id);
      setLoading(false);
      props.setOpen(false);
      location.href = "/users/plans/redirect_after_plan_change";
    }
  };

  const toYen = (amount) => {
    return amount.toLocaleString();
  };

  const freePlanRemainDays = () => {
    if (props.isDallE) {
      return props.user.freeUserImageRemainingDays;
    } else {
      return props.user.freeUserTextRemainingDays;
    }
  };

  const trialEndAlert = () => {
    if (!props.user.isTrial) {
      return <></>;
    } else {
      return (
        <div className="rounded-md bg-yellow-50 p-4 mt-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-bold text-yellow-800">
                体験プランが終了します。
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  体験プランを終了して{props.plan.amount}
                  円のプランに加入しますか？
                  <br />
                  ※料金は即時決済されます
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                {loading ? (
                  <>
                    <div className="flex justify-center mt-3">
                      <ReactLoading color={`#009dff`} />
                    </div>
                  </>
                ) : (
                  <>
                    {freePlanRemainDays() != null && (
                      <div className="rounded-md bg-red-50 p-4 mb-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <div className="text-sm font-medium text-red-800">
                              まだ有料プランの未利用分が{freePlanRemainDays()}
                              日残っています。
                              <br />
                              再度
                              全額決済されてしまいますがよろしいでしょうか？
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {props.title}
                        </Dialog.Title>
                        {trialEndAlert()}
                        {props.message != "" && (
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {props.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <table className="w-full text-left text-gray-900">
                      <thead className="uppercase bg-gray-50">
                        <tr>
                          <th className="p-3 text-xs">プラン名</th>
                          <th className="p-3 text-xs">お支払い金額</th>
                          <th className="p-3 text-xs">支払いサイクル</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-3 text-sm">{props.plan.name}</td>
                          <td className="p-3 text-sm">
                            {toYen(props.plan.amount)}円
                          </td>
                          <td className="p-3 text-sm">
                            {props.plan.cycleHuman}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {props.plan.description && (
                      <>
                        <div className="text-xs mt-2 text-gray-500">
                          {props.plan.description}
                        </div>
                      </>
                    )}
                    <div className="text-xs mt-1 text-gray-500">
                      ※サブスクリプション契約はお客様からの解約処理が行われるまで自動更新されます。
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                        onClick={handleClickPlanChangeBtn}
                      >
                        {props.cta ? props.cta : "プランを変更する"}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => props.setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        キャンセル
                      </button>
                    </div>
                    <h4 className="text-base font-semibold leading-8 text-gray-900">
                      利用規約
                    </h4>
                    <div className="px-4 py-2 bg-gray-50 overflow-auto max-h-[200px] rounded-lg">
                      <p className="text-xs whitespace-pre-wrap text-gray-600">
                        {termsText}
                      </p>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default ChangePlanConfirm;
