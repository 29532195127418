import React, { useState } from "react";
import { User } from "../../../interfaces/user";
import { Plan } from "../../../interfaces/plan";
import { Coupon } from "../../../interfaces/coupon";
import ChangePlanConfirm from "../../partials/modals/ChangePlanConfirm";
import ChangeToFreeConfirm from "../../partials/modals/ChangeToFreeConfirm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ArrowRight } from "lucide-react";

type Props = {
  plans: Plan[];
  user: User;
  stripeKey: string;
  ThreeDSecureSupported: boolean;
  coupon: Coupon | null;
};

const UsersPlansTable: React.FC<Props> = (props) => {
  const [planToChange, setPlanToChange] = useState<Plan | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openFreeModal, setOpenFreeModal] = useState(false);
  const stripePromise = loadStripe(props.stripeKey);
  const isFree = () => {
    return props.user.planName === "free";
  };

  const isWithinCancelPeriod = () => {
    return props.user.withinCancelPeriod;
  };
  const isCurrentPlan = (plan) => {
    return props.user.plan?.id == plan.id;
  };

  /**
   * 対象プランが上位かどうか？
   */
  const isPlanSuperior = (planName) => {
    let currentPlanIdx = props.plans
      .map((item) => item.name)
      .indexOf(props.user.planName);
    let targetPlanIdx = props.plans.map((item) => item.name).indexOf(planName);
    return targetPlanIdx > currentPlanIdx;
  };

  const planBtn = (plan: Plan) => {
    if (isCurrentPlan(plan)) {
      return currentPlanBtn();
    } else {
      return upgradeBtn(plan);
    }
  };

  const upgradeBtn = (plan: Plan) => {
    // props.user.hasCard で条件分岐
    if (props.user.hasCard) {
      return (
        <a
          href={`#`}
          className="text-white bg-gradient-to-r from-[#fad72b] to-[#f8aa13] hover:opacity-90 focus:ring-4 focus:ring-blue-200 font-bold rounded-full text-lg py-3 text-center my-8 shadow-md"
          onClick={(e) => {
            e.preventDefault();
            handleClickPlanChange(plan);
          }}
        >
          <div className="relative flex justify-center items-center">
            <div>このプランに変更</div>
            <ArrowRight className="absolute right-3 w-6 h-6" />
          </div>
        </a>
      );
    } else {
      return (
        <a
          href={`/users/stripes/create_checkout_session?plan_id=${plan.id}`}
          data-method="post"
          className="text-white bg-gradient-to-r from-[#fad72b] to-[#f8aa13] hover:opacity-90 focus:ring-4 focus:ring-blue-200 font-bold rounded-full text-lg py-3 text-center my-8 shadow-md"
        >
          <div className="relative flex justify-center items-center">
            <div>このプランにする</div>
            <ArrowRight className="absolute right-3 w-6 h-6" />
          </div>
        </a>
      );
    }
  };

  const downgradeFreeBtn = () => {
    return (
      <a
        href={`#`}
        className="text-gray-800 bg-gray-300 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
          handleClickFree();
        }}
      >
        ダウングレードする
      </a>
    );
  };

  const currentPlanBtn = () => {
    return (
      <a
        href="#"
        className="text-gray-800 bg-gray-300 focus:ring-4 font-bold rounded-lg text-md px-5 py-4 text-center my-8"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        現在のプラン
      </a>
    );
  };

  const handleClickPlanChange = (plan: Plan) => {
    setPlanToChange(plan);
    setOpenModal(true);
  };
  const handleClickFree = () => {
    setOpenFreeModal(true);
  };

  const trialLabel = (trialPeriodDays) => {
    if (trialPeriodDays == null) {
      return <></>;
    }
    if (props.user.hasExperiencedTrial) {
      return <></>;
    }
    if (props.user.hasCard) {
      return <></>;
    }
    return (
      <div className="py-1 px-3 text-sm text-blue-800 bg-blue-100 rounded font-bold">
        <>クラウドワークスユーザー様限定</>
        <br />
        <>{trialPeriodDays}日間無料トライアル</>
      </div>
    );
  };

  const toYen = (amount) => {
    return amount.toLocaleString();
  };

  const toYenPerDay = (amount) => {
    return Math.round(amount / 30);
  };

  const orderClassName = (plan: Plan) => {
    if (plan.interval == "year") {
      return "order-1";
    } else {
      if (plan.intervalCount == 6) {
        return "order-2";
      } else {
        return "order-3";
      }
    }
  };

  const planTermName = (plan: Plan) => {
    if (plan.interval == "year") {
      return "年間プラン";
    } else {
      if (plan.intervalCount == 6) {
        return "6ヶ月プラン";
      } else {
        return "1ヶ月プラン";
      }
    }
  };

  const planLabelName = (plan: Plan) => {
    if (plan.interval == "year") {
      return "一番お得！";
    } else {
      if (plan.intervalCount == 6) {
        return "少しお得";
      } else {
        return "期間限定割引";
      }
    }
  };

  const planDesc = (plan: Plan) => {
    if (plan.interval == "year") {
      return "最大40%お得なプラン";
    } else {
      if (plan.intervalCount == 6) {
        return "最大20%お得なプラン";
      } else {
        return "期間限定価格";
      }
    }
  };

  const totalAmount = (plan) => {
    if (plan.interval == "month" && plan.intervalCount == 1) {
      return (
        <>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="text-xs mt-2 font-normal text-gray-900">
            ¥ {toYen(plan.amount)}(税込) {perIntervalText(plan)}
          </div>
          <div className="text-xs mt-1 font-normal text-gray-900">
            ※契約は自動更新となります
          </div>
        </>
      );
    }
  };

  const intervalText = (plan: Plan) => {
    if (plan.interval == "year") {
      return "一年分一括払い";
    } else if (plan.interval == "month" && plan.intervalCount == 6) {
      return "6ヶ月分一括払い";
    }
  };

  const perIntervalText = (plan: Plan) => {
    if (plan.interval == "year") {
      return "の年間ごとのお支払いになります";
    } else if (plan.interval == "month" && plan.intervalCount == 6) {
      return "の6ヶ月ごとのお支払いになります";
    }
  };

  const couponTag = (plan) => {
    if (plan.interval == "year") {
      if (props.coupon && props.coupon?.discountAmount != 0) {
        return (
          <span
            className="py-1 px-3 text-md bg-blue-100 rounded font-bold text-white"
            style={linearGradientStyle}
          >
            初回{props.coupon?.discountAmount}円割引クーポン
          </span>
        );
      }
    }
  };

  const linearGradientStyle = {
    background: "linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)",
  };

  return (
    <>
      <div className="grid gap-8 lg:grid-cols-3 xl:gap-10">
        {isFree() || isWithinCancelPeriod() ? (
          <>{/* プレミアムプラン参加してない場合フリー非表示 */}</>
        ) : (
          // プラン加入してる人にフリープラン表示
          <>
            <div
              className={`order-5 flex flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              <h3 className="mb-4 text-2xl font-medium text-gray-900">
                Freeプラン
              </h3>
              <span className="text-5xl font-extrabold text-gray-900">¥ 0</span>
              {isFree() ? <>{currentPlanBtn()}</> : <>{downgradeFreeBtn()}</>}

              <ul role="list" className="space-y-4 text-left text-gray-900">
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-ad w-5"></i>
                  <span>広告表示あり</span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-person-dress w-5"></i>
                  <span>テキスト生成数無制限</span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-regular fa-message w-5"></i>
                  <span>文字数→無制限</span>
                </li>
                <li className="flex items-center space-x-3 text-gray-400">
                  <i className="fas fa-fast-forward w-5"></i>
                  <span className="line-through">高速文字生成</span>
                </li>
                <li className="flex items-center space-x-3 text-gray-400">
                  <i className="fa-solid fa-download w-5"></i>
                  <span className="line-through">
                    生成コンテンツのダウンロード
                  </span>
                </li>
                <li className="flex items-center space-x-3 text-gray-400">
                  <i className="fa-solid fa-save w-5"></i>
                  <span className="line-through">生成コンテンツの保存機能</span>
                </li>
                <li className="flex items-center space-x-3 text-gray-400">
                  <i className="fa-solid fa-envelope w-5"></i>
                  <span className="line-through">
                    生成コンテンツのメール送信機能
                  </span>
                </li>
              </ul>
            </div>
          </>
        )}

        {props.plans.map((plan) => {
          return (
            <div
              key={plan.name}
              className={`${orderClassName(
                plan
              )} flex relative flex-col p-6 mx-auto w-full text-center bg-white rounded-lg border shadow xl:p-8`}
            >
              {couponTag(plan)}
              <div className="mb-2">{trialLabel(plan.trialPeriodDays)}</div>
              <div className="flex items-center justify-between gap-x-4 mb-4">
                <h3
                  id="tier-startup"
                  className="text-lg font-semibold leading-8 text-orange-500"
                >
                  {planTermName(plan)}
                </h3>
                <p className="rounded-sm bg-orange-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                  {planLabelName(plan)}
                </p>
              </div>
              <div className="flex items-end justify-center">
                <div className="text-3xl font-extrabold text-gray-900">¥</div>
                <div className="text-5xl font-extrabold text-gray-900">
                  {toYen(plan.amountPerMonth)}
                </div>
                <div className="text-lg font-extrabold text-gray-900">
                  &nbsp;/&nbsp;月(税込)
                </div>
              </div>
              {totalAmount(plan)}
              <span className="rounded-sm mt-4 bg-orange-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-orange-500">
                {planDesc(plan)}
              </span>

              {planBtn(plan)}

              <ul role="list" className="space-y-4 text-left text-gray-900">
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-ad w-5"></i>
                  <span className="font-bold underline decoration-orange-500 decoration-4">
                    広告表示なし
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fas fa-fast-forward w-5"></i>
                  <span className="font-bold underline decoration-orange-500 decoration-4">
                    高速文字生成
                  </span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-person-dress w-5"></i>
                  {plan.name == "premium" ? (
                    <span>テキスト生成数無制限</span>
                  ) : (
                    <span>
                      生成数{plan.generateLimit}回 / 月（月ごとにリセット）
                    </span>
                  )}
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-regular fa-message w-5"></i>
                  <span>文字数→無制限</span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-download w-5"></i>
                  <span>生成コンテンツのダウンロード</span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-save w-5"></i>
                  <span>生成コンテンツの保存機能</span>
                </li>
                <li className="flex items-center space-x-3">
                  <i className="fa-solid fa-envelope w-5"></i>
                  <span>生成コンテンツのメール送信機能</span>
                </li>
                {plan.name == "premium" && (
                  <li className="flex items-center space-x-3">
                    <i className="fa-solid fa-yen-sign w-5"></i>
                    <span>
                      1日約{toYenPerDay(plan.amountPerMonth)}円で使い放題
                    </span>
                  </li>
                )}
                <li className="flex items-center space-x-3">
                  <span>
                    ※画像生成は
                    <a
                      href="/users/option_plans/image_generator"
                      className="text-blue-600 underline"
                      target="_blank"
                    >
                      別途プラン契約
                    </a>
                    が必要です
                  </span>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
      <Elements stripe={stripePromise}>
        {planToChange != null && (
          <ChangePlanConfirm
            open={openModal}
            setOpen={setOpenModal}
            title={`プランを変更します。よろしいでしょうか？`}
            plan={planToChange}
            stripeKey={props.stripeKey}
            ThreeDSecureSupported={props.ThreeDSecureSupported}
            user={props.user}
          />
        )}
      </Elements>
      <ChangeToFreeConfirm
        open={openFreeModal}
        setOpen={setOpenFreeModal}
        title={`フリープランに変更します。`}
        message={`AIマーケティング大学内のプレミアムプラン限定コミュニティの参加資格も失います。本当にダウングレードしますか？`}
        user={props.user}
      />
    </>
  );
};
export default UsersPlansTable;
