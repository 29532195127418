import React, { ReactNode, useState } from "react";

type Props = {
  link: string;
  icon: ReactNode;
  linkName: string;
};

const UsersAi: React.FC<Props> = (props) => {
  return (
    <>
      <a
        className="border p-3 grid justify-items-center h-64"
        href={props.link}
      >
        <div className="flex items-center justify-center py-10">
          <div className="text-cwaiblue-600 w-20 h-20 flex items-center justify-center mr-4 ml-2 text-xl">
            {props.icon}
          </div>
        </div>

        <div className="w-full flex items-center justify-center bg-gray-100 rounded-full h-10">
          <a
            className="text-cwaiblue-600 font-semibold break-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cwaiblue-600 hover:text-cwaiblue-500"
            href={props.link}
          >
            {props.linkName}のAIメニュー
          </a>
        </div>
      </a>
    </>
  );
};
export default UsersAi;
