import React, { useState } from "react";

import { Ai } from "@/interfaces/ai";
import { User } from "@/interfaces/user";
import AiCard from "@/components/biz/partials/ais/AiCard";

type Props = {
  favoriteAiGroups: {
    title: string;
    slug: string;
    ais: Ai[];
  }[];
  favorites: Ai[];
  user: User;
};

const AiMenuTextJobRole: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);

  const [selectedCategory, setSelectedCategory] = useState<string>("sales");

  const toggleCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const getCategoryClassName = (category: string) => {
    return selectedCategory === category
      ? "w-64 inline-block px-4 py-2 bg-cwaiblue-600 text-white" // 選択されているカテゴリのクラス名
      : "w-64 inline-block px-4 py-2 text-gray-500 hover:text-gray-400"; // 選択されていないカテゴリのクラス名
  };

  return (
    <>
      <div className="h-full">
        <p className="text-sm text-left font-bold border-b py-2 pl-3 mb-10 bg-gray-100">
          お気に入り
        </p>

        <p className="text-sm text-center font-bold pb-3">お気に入り一覧</p>
        <div className="">
          <div className="mb-4">
            <ul className="flex flex-wrap text-sm font-bold text-center justify-center">
              {props.favoriteAiGroups.map((group) => (
                <li className="bg-gray-100" key={`tab-` + group.slug}>
                  <button
                    className={getCategoryClassName(group.slug)}
                    onClick={() => toggleCategory(group.slug)}
                  >
                    {group.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {props.favoriteAiGroups.map((group) => (
            <div key={`group-` + group.slug}>
              <div className={selectedCategory === group.slug ? "" : "hidden"}>
                {group.ais.length > 0 ? (
                  <>
                    <div className="text-sm mb-6 sm:px-8 px-3">
                      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                        {group.ais.map((ai) => {
                          return (
                            <AiCard
                              key={`ai-${group.slug}-${ai.slug}`}
                              ai={ai}
                              user={props.user}
                              favorites={favorites}
                              setFavorites={setFavorites}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="text-center text-gray-500 border sm:max-w-sm mx-auto py-10 text-sm">
                    <p>お気に入りのAIメニューがありません</p>
                    <p>AIメニューからお気に入りを探してください</p>
                    <a
                      href={`/ai_menu/text/category/${group.slug}`}
                      type="button"
                      className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-6"
                    >
                      {group.title}をみる
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default AiMenuTextJobRole;
