import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createRagFile, } from "../../../biz/libs/api/rag_files";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  handleShowDialog: (value: boolean) => void;
  handleLoading: (value: boolean) => void;
  file: File | null;
};
const FileUploadDialog: React.FC<Props> = (props) => {
  const [open, setOpen] = useState(props.open);

  const handleUploadRagFile = async () => {
    try {
      handleShowDialog(false);
      props.handleLoading(true);
      await createRagFile(props.file);
      // 画面リロード後にToastを表示するためのフラグをセット
      sessionStorage.setItem("showToast", "true");
      sessionStorage.setItem("toastMessage", "ファイルをアップロードしました");
      window.location.reload();
    } catch (error) {
      toast.error(error.response.data.message);
      handleShowDialog(false);
    } finally {
      props.handleLoading(false);
    }
  };

  const handleShowDialog = (value: boolean) => {
    setOpen(value);
    props.handleShowDialog(value);
  };

  const handleCancel = () => {
    handleShowDialog(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleShowDialog}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        新規データ登録
                      </Dialog.Title>
                      <div className="border-0 border-t mt-4"></div>
                      <div className="mt-4">
                        {props.file && (
                          <div className="mt-4">
                            <h4 className="text-sm text-left text-gray-900 mb-4">
                              このファイルデータを登録しますか？
                            </h4>
                            <input
                              type="text"
                              disabled={true}
                              className="text-sm text-left border border-gray-300 bg-gray-100 rounded-lg py-2 px-3 w-full"
                              value={props.file.name}
                            />
                          </div>
                        )}
                        <div className="mt-10 flex justify-center">
                          <button
                            className="w-48 rounded bg-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={handleCancel}
                          >
                            いいえ
                          </button>

                          <button
                            type="button"
                            className="ml-6 w-48 rounded bg-cwaiblue-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-cwaiblue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cwaiblue-600"
                            onClick={handleUploadRagFile}
                          >
                            はい
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default FileUploadDialog;
