import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";

const frequencies = [
  { value: "monthly", label: "月払い", priceSuffix: "/月（税込）" },
  { value: "annually", label: "年払い", priceSuffix: "/月（税込）" },
];
const tiers = [
  {
    name: "月額会員",
    id: "4",
    href: "/plan_redirect/premium?amount=4980",
    price: { monthly: "¥4,980" },
    btnText: "今すぐ購入する",
    features: [
      "テキスト生成回数 → 無制限",
      "個別チャットサポート",
      "限定動画講座の閲覧",
      "【特典】画像生成100枚分チケット",
    ],
    waribikiText: "¥4,980（税込）",
    waribiki: false,
    mostPopular: false,
    mostPremium: "",
    btnTopText: "年間利用料 59,760円 (税込)",
    disclaimer:
      "※キャンペーン適用条件・購入方法: 本ページにある「今すぐ購入する」ボタンからご購入いただいた方のみが対象です。別の画面からの購入は適用外です。・プランの登録状況: 現在、有料プランに登録していない方が対象です。すでに有料プランに登録されている場合は適用外となります。",
  },
  {
    name: "年間会員",
    id: "2",
    href: "/plan_redirect/premium?amount=35760",
    price: { monthly: "¥2,980" },
    waribikiText: "¥4,980（税込）",
    waribiki: true,
    btnText: "今すぐ購入する",
    features: [
      "テキスト生成回数 → 無制限",
      "個別チャットサポート",
      "限定動画講座の閲覧",
      "【特典】画像生成100枚分チケット",
    ],
    mostPopular: true,
    mostPremium: "1年で24,000円お得！",
    btnTopText: "年間利用料 35,760円 (税込)",
    disclaimer:
      "※キャンペーン適用条件・購入方法: 本ページにある「今すぐ購入する」ボタンからご購入いただいた方のみが対象です。別の画面からの購入は適用外です。・プランの登録状況: 現在、有料プランに登録していない方が対象です。すでに有料プランに登録されている場合は適用外となります。",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const sortedTiers = isMobile ? [...tiers].reverse() : tiers;

  return (
    <div className="sm:py-24 py-12 pt-12">
      <div className="mx-auto max-w-5xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-lg font-bold tracking-tight text-white sm:text-3xl">
            ＼ 期間限定割引キャンペーン中 ／
          </p>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {sortedTiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "bg-white/5 ring-2 ring-indigo-500"
                  : "ring-1 ring-white/10",
                "rounded-3xl p-8 xl:p-10"
              )}
            >
              <div
                className={`flex ${
                  isMobile ? "flex-col" : "items-center justify-between"
                } gap-4`}
              >
                <h3
                  id={tier.id}
                  className="text-2xl font-semibold leading-8 text-white"
                >
                  {tier.name}
                </h3>
                {tier.mostPremium ? (
                  <p className="rounded-sm bg-[#f6f94c] px-4 py-2 text-lg text-center font-semibold leading-5 text-gray-900">
                    {tier.mostPremium}
                  </p>
                ) : null}
              </div>

              <p
                className={`mt-4 text-xl leading-6 ${
                  tier.waribiki
                    ? "text-white line-through decoration-red-500 decoration-2"
                    : "text-transparent"
                }`}
              >
                {tier.waribikiText}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-5xl font-bold tracking-tight text-white">
                  {tier.price[frequency.value]}
                </span>
                <span className="text-sm font-semibold leading-6 text-gray-300">
                  {frequency.priceSuffix}
                </span>
              </p>
              <span className="rounded-md mt-6 w-full block text-center bg-gray-700 px-2.5 py-2 text-md leading-5 text-white">
                {tier.btnTopText}
              </span>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white shadow-sm hover:opacity-90"
                    : "bg-gray-500 text-white hover:bg-white/20 focus-visible:outline-white",
                  "mt-6 block rounded-md py-3 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                )}
              >
                {tier.btnText}
              </a>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-white"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <p className="mt-4 text-md leading-6 text-gray-300">
                {tier.disclaimer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
