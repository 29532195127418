import React, { useMemo, useEffect, useState } from "react";
import { User } from "../../../interfaces/user";
import { createConsumer } from "@rails/actioncable";
import { signOut } from "../../../libs/api/user";
type Props = {
  user: User;
  cableEndpoint?: string;
};

const CheckConnectCount: React.FC<Props> = (props) => {
  const cable = useMemo(() => createConsumer(), []);
  const [showAlertModal, setShowAlertModal] = useState(false);
  let tokenKey = `device_${props.user.token}`;

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const setOrGetLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return storedValue;
    } else {
      const newValue = generateRandomString(20);
      localStorage.setItem(key, newValue);
      return newValue;
    }
  };

  const getAllDeviceCount = () => {
    var pattern = "device_"; // 例えば"device_"で始まるキーを対象とする場合
    var matchCount = 0;
    Object.keys(localStorage).forEach(function (key) {
      if (key.includes(pattern)) {
        var value = localStorage.getItem(key);
        // マッチしたキーに対する値の処理
        // マッチカウントを増やす
        matchCount++;
      }
    });
    return matchCount;
  };

  useEffect(() => {
    const sub = cable.subscriptions.create(
      { channel: "DeviceChannel", token: props.user.token },
      {
        connected: () => {
          let slug = setOrGetLocalStorage(tokenKey);
          sub.perform("handle_sign_in", {
            slug: slug,
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            deviceMemory: navigator.deviceMemory,
            screenWidth: screen.width,
            screenHeight: screen.height,
            colorDepth: screen.colorDepth,
            pixelDepth: screen.pixelDepth,
          });
          sub.perform("save_all_device_count", {
            allDeviceCount: getAllDeviceCount(),
          });
        },
        received: async (res) => {
          let currentUserDevice = setOrGetLocalStorage(tokenKey);
          if (currentUserDevice != res.device.slug) {
            //setShowAlertModal(true);
            //ここで強制ログアウト
            await signOut();
            //sign in にparameterをつけて強制移動
            window.location.href = "/users/sign_in?device_alert=1";
          }
        },
      }
    );
    return () => {
      sub.unsubscribe();
    };
  }, [props.user]);

  return <></>;
};
export default CheckConnectCount;
