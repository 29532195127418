import React, { useState } from "react";

import { Ai } from "../../../../interfaces/ai";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";
import { toggleUserFavorite } from "../../../../libs/api/users/user_favorite_ai";

type Props = {
  ai: Ai;
  user?: User;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const UsersAi: React.FC<Props> = (props) => {
  const handleClickCard = (e) => {
    e.preventDefault();
    location.href = `/users/ais/${props.ai.slug}`;
  };

  return (
    <>
      <div
        onClick={(e) => handleClickCard(e)}
        className="border p-3 flex items-top align-items-start cursor-pointer"
      >
        <div className="flex items-center justify-center">
          <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center mr-4 ml-2 text-xl">
            <img
              alt=""
              className="object-cover w-16 h-16 flex-shrink-0 rounded-md bg-gray-300"
              src={props.ai.image.thumb.url}
            />
          </div>
        </div>
        <div className="mr-4 w-full">
          <h3 className="text-sm font-bold">{props.ai.name}</h3>
          <p className="text-xs text-gray-500">{props.ai.intro}</p>
        </div>
      </div>
    </>
  );
};
export default UsersAi;
