import React, { useState } from "react";

import { Ai } from "@/interfaces/ai";
import { User } from "@/interfaces/user";
import AiCard from "@/components/biz/partials/ais/AiCard";

type Props = {
  generalAis: Ai[];
  salesAis: Ai[];
  marketingAis: Ai[];
  user?: User;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const UsersAi: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);
  const [selectedCategory, setSelectedCategory] = useState<string>("general");

  const toggleCategory = (category: string) => {
    setSelectedCategory(category);
  };

  const getCategoryClassName = (category: string) => {
    return selectedCategory === category
      ? "w-64 inline-block px-4 py-2 bg-cwaiblue-600 text-white" // 選択されているカテゴリのクラス名
      : "w-64 inline-block px-4 py-2 text-gray-500 hover:text-gray-400"; // 選択されていないカテゴリのクラス名
  };

  const renderRanking = () => {
    switch (selectedCategory) {
      case "general":
        return (
          <>
            <div>
              <div className="text-sm mb-6">
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                  {props.generalAis.map((ai) => {
                    return (
                      <AiCard
                        key={`ai-${ai.slug}`}
                        ai={ai}
                        user={props.user}
                        favorites={favorites}
                        setFavorites={setFavorites}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      case "sales":
        return (
          <>
            <div>
              <div className="text-sm mb-6">
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                  {props.salesAis.map((ai) => {
                    return (
                      <AiCard
                        key={`ai-${ai.slug}`}
                        ai={ai}
                        user={props.user}
                        favorites={favorites}
                        setFavorites={setFavorites}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      case "marketing":
        return (
          <>
            <div>
              <div className="text-sm mb-6">
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                  {props.marketingAis.map((ai) => {
                    return (
                      <AiCard
                        key={`ai-${ai.slug}`}
                        ai={ai}
                        user={props.user}
                        favorites={favorites}
                        setFavorites={setFavorites}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div>
              <div className="text-sm mb-6">
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                  {props.generalAis.map((ai) => {
                    return (
                      <AiCard
                        key={`ai-${ai.slug}`}
                        ai={ai}
                        user={props.user}
                        favorites={favorites}
                        setFavorites={setFavorites}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="">
        <div className="mb-4">
          {/* ランキングのタブ */}
          <ul className="flex flex-wrap text-sm font-bold text-center justify-center">
            <li className="bg-gray-100">
              <button
                className={getCategoryClassName("general")}
                onClick={() => toggleCategory("general")}
              >
                総合
              </button>
            </li>
            <li className="bg-gray-100">
              <button
                className={getCategoryClassName("sales")}
                onClick={() => toggleCategory("sales")}
              >
                営業
              </button>
            </li>
            <li className="bg-gray-100">
              <button
                className={getCategoryClassName("marketing")}
                onClick={() => toggleCategory("marketing")}
              >
                マーケティング
              </button>
            </li>
          </ul>
        </div>
        <div className="sm:px-8 px-3">
          {/* 総合ランキング */}
          <div>{renderRanking()}</div>
        </div>
      </div>
    </>
  );
};
export default UsersAi;
