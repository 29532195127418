import React, { useState, useEffect } from "react";

import { MainCategory } from "../../../interfaces/main_category";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  FileText,
  Home,
  Star,
  Users,
  Send,
  Folders
} from "lucide-react";

type Props = {
  currentOrganizationUserRole: string;
  // TODO: 本番で特定のorg(crowdsworks ai)のみ表示するために利用しているので削除する
  currentOrganizationName: string;
  mainCategories: MainCategory[];
};

const Sidebar: React.FC<Props> = (props) => {
  const [openTextAiMenu, setOpenTextAiMenu] = useState(() => {
    const storedState = localStorage.getItem("openTextAiMenu");
    return storedState ? JSON.parse(storedState) : false;
  });

  const toggleOpenTextAiMenu = (e) => {
    e.preventDefault();
    setOpenTextAiMenu(!openTextAiMenu);
  };

  useEffect(() => {
    localStorage.setItem("openTextAiMenu", JSON.stringify(openTextAiMenu));
  }, [openTextAiMenu]);

  return (
    <>
      <div className="h-screen px-2 pt-2 pb-24 overflow-y-auto bg-cwaiblue-600 text-sm text-white">
        <ul className="space-y-1">
          <li>
            <a
              className="flex items-center px-2 py-1.5 text-md font-semibold rounded-lg hover:bg-primary hover:text-white"
              href="/"
            >
              <div>
                <Home className="w-5 h-5" />
              </div>
              <span className="ml-2">Home</span>
            </a>
          </li>

          <li>
            {/* TODO: リンク先設定 */}
            <button
              className="w-full group flex rounded-md px-2 py-1.5 text-md leading-6 font-semibold items-center hover:bg-primary relative hover:text-white"
              onClick={(e) => toggleOpenTextAiMenu(e)}
            >
              <div>
                <FileText className="w-5 h-5" />
              </div>
              <span className="ml-2">テキスト生成</span>
              <FontAwesomeIcon
                icon={openTextAiMenu ? faChevronDown : faChevronRight}
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
              />
            </button>
          </li>
          {openTextAiMenu && (
            <ul role="list" className="flex flex-1 flex-col pl-3">
              {props.mainCategories.map((mainCategory) => {
                return (
                  <li key={`sidebar-${mainCategory.slug}`}>
                    <a
                      className="group flex rounded-md px-2 py-1.5 text-md leading-6 font-semibold items-center hover:bg-primary relative hover:text-white"
                      href={`/ai_menu/text/category/${mainCategory.slug}`}
                    >
                      <div className="flex items-center">
                        <i
                          className={`${mainCategory.fontawesomeClass} text-lg h-5 w-5`}
                        ></i>
                      </div>
                      <span className="ml-2">{mainCategory.name}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}

          {/* TODO: リンク先設定 */}
          <li>
            <a
              className="flex items-center px-2 py-1.5 text-md font-semibold rounded-lg hover:bg-primary hover:text-white"
              href="/ai_menu/favorites"
            >
              <div>
                <Star className="w-5 h-5" />
              </div>
              <span className="ml-2">お気に入り</span>
            </a>
          </li>

          {
            props.currentOrganizationUserRole === "admin" && (
              <li>
                <a
                  className="flex items-center px-2 py-1.5 text-md font-semibold rounded-lg hover:bg-primary hover:text-white"
                  href="/users/users"
                >
                  <div>
                    <Users className="w-5 h-5"/>
                  </div>
                  <span className="ml-2">ユーザー管理</span>
                </a>
              </li>
            )
          }

          {
            props.currentOrganizationUserRole === "admin" && props.currentOrganizationName === "crowdworks ai" && (
              <li>
                <a
                  className="flex items-center px-2 py-1.5 text-md font-semibold rounded-lg hover:bg-primary hover:text-white"
                  href="/rag_files"
                >
                  <div>
                    <Folders className="w-5 h-5"/>
                  </div>
                  <span className="ml-2">データ管理</span>
                </a>
              </li>
            )
          }

          <li>
            <a
              className="flex items-center px-2 py-1.5 text-md font-semibold rounded-lg hover:bg-primary hover:text-white"
              href="https://forbiz.crowdworks-ai.com/1-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <Send className="w-5 h-5" />
              </div>
              <span className="ml-2">お問い合わせ</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Sidebar;
