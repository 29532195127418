import React, { useState } from "react";

import { Ai } from "../../../../interfaces/ai";
import { User } from "../../../../interfaces/user";
import { toast } from "react-toastify";
import { toggleUserFavorite } from "../../../../libs/api/users/user_favorite_ai";

type Props = {
  ai: Ai;
  user?: User;
  favorites: Ai[];
  setFavorites: (callback: (prevFavorites: Ai[]) => Ai[]) => void;
};

const UsersAi: React.FC<Props> = (props) => {
  const handleClickCard = (e) => {
    e.preventDefault();
    location.href = `/users/ais/${props.ai.slug}`;
  };

  const isFavorite = () => {
    return props.favorites.map((f) => f.id).includes(props.ai.id);
  };

  const addFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) => [...prevFavorites, ai]);
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("addFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りに追加しました");
  };

  const removeFavorite = async (ai: Ai) => {
    props.setFavorites((prevFavorites: Ai[]) =>
      prevFavorites.filter((faAi) => faAi.id !== ai.id)
    );
    await toggleUserFavorite(ai.slug);
    const event = new CustomEvent("removeFavorite", {
      detail: { ai },
    });
    window.dispatchEvent(event);
    toast.success("お気に入りから解除しました");
  };

  return (
    <>
      <div className="border bg-white flex flex-col">
        <div className="pt-3 flex-none">
          <div className="flex items-center justify-center">
            <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center text-xl">
              <img
                alt=""
                className="object-cover w-16 h-16 flex-shrink-0 rounded-md bg-gray-300"
                src={props.ai.image.thumb.url}
              />
            </div>
          </div>
        </div>
        <div className="flex-auto pt-3 mx-4">
          <h3 className="text-sm font-bold break-all">{props.ai.name}</h3>
          <p className="pt-2 text-xs text-gray-500 line-clamp-3 break-all">
            {props.ai.intro}
          </p>
        </div>
        <div className="m-2 flex-none grid gap-1 grid-cols-2">
          {}
          {isFavorite() ? (
            <>
              <button
                type="button"
                className="rounded bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 w-full hover:bg-gray-50"
                onClick={() => removeFavorite(props.ai)}
              >
                解除
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 w-full hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cwaiblue-600"
                onClick={() => addFavorite(props.ai)}
              >
                お気に入り
              </button>
            </>
          )}

          <button
            onClick={(e) => handleClickCard(e)}
            type="button"
            className="rounded bg-cwaiblue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cwaiblue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cwaiblue-600 w-full"
          >
            詳しく見る
          </button>
        </div>
      </div>
    </>
  );
};
export default UsersAi;
