import React, { useState } from "react";

import { Ai } from "@/interfaces/ai";
import { MainCategory } from "@/interfaces/main_category";
import { User } from "@/interfaces/user";
import AiCardHorizotal from "@/components/biz/partials/ais/AiCardHorizotal";
import AiMenuLinkCard from "@/components/biz/home/AiMenuLinkCard";
import RatedRanking from "@/components/biz/home/RatedRanking";

type Props = {
  generalAis: Ai[];
  salesAis: Ai[];
  marketingAis: Ai[];
  mainCategories: MainCategory[];
  relatedGeneratedAis: Ai[];
  recommendedAis: Ai[];
  user: User;
  favorites: Ai[];
};

const UsersIndex: React.FC<Props> = (props) => {
  const [favorites, setFavorites] = useState<Ai[]>(props.favorites);
  const mainCategoriesForLinkCard = props.mainCategories.slice(0, 3);

  return (
    <>
      <div className="pt-10 mt-3 mb-16">
        <p className="text-xl text-center font-bold">
          こんにちは、{props.user.name}さん！
        </p>

        <p className="mt-12 text-sm text-center font-bold">
          どのAIメニューを生成しますか？
        </p>
        <div className="mt-3 grid gap-3 sm:grid-cols-3 grid-col-1 sm:px-8 px-3">
          {mainCategoriesForLinkCard.map((mainCategory) => {
            return (
              <AiMenuLinkCard
                key={`main-category-${mainCategory.slug}`}
                link={`/ai_menu/text/category/${mainCategory.slug}`}
                icon={
                  <i
                    className={`${mainCategory.fontawesomeClass} text-lg h-12 w-12`}
                  ></i>
                }
                linkName={mainCategory.name}
              />
            );
          })}
        </div>

        <p className="mt-10 text-sm text-center font-bold">最近生成したAIメニュー</p>
        <div className="mt-3 py-3 bg-gray-100 sm:px-8 px-3">
          <div className="grid gap-2 md:grid-cols-5 grid-cols-2">
            {props.relatedGeneratedAis.map((ai) => {
              return (
                <AiCardHorizotal
                  key={`ai-${ai.slug}`}
                  ai={ai}
                  user={props.user}
                  favorites={favorites}
                  setFavorites={setFavorites}
                />
              );
            })}
          </div>
        </div>

        <p className="pt-10 text-sm text-center font-bold">人気ランキング</p>
        <div className="pt-3">
          <RatedRanking
            generalAis={props.generalAis}
            salesAis={props.salesAis}
            marketingAis={props.marketingAis}
            user={props.user}
            favorites={favorites}
            setFavorites={setFavorites}
          />
        </div>

        <p className="mt-10 text-sm text-center font-bold">
          おすすめのAIメニュー
        </p>
        <div className="mt-3 py-3 bg-gray-100 sm:px-8 px-3">
          <div className="grid gap-2 md:grid-cols-5 grid-cols-2">
            {props.recommendedAis.map((ai) => {
              return (
                <AiCardHorizotal
                  key={`ai-${ai.slug}`}
                  ai={ai}
                  user={props.user}
                  favorites={favorites}
                  setFavorites={setFavorites}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default UsersIndex;
