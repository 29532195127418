import React, { useContext } from "react";
import { Ai } from "../../../../../interfaces/ai";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";

type Props = {
  ai: Ai;
  favorites: Ai[];
  addFavorite: (ai: Ai) => void;
  removeFavorite: (ai: Ai) => void;
};
const UserAiChatHeader: React.FC<Props> = (props) => {
  const isFavorite = () => {
    return props.favorites.map((f) => f.id).includes(props.ai.id);
  };
  const handleFavoriteClick = () => {
    if (isFavorite()) {
      props.removeFavorite(props.ai);
      toast.success("お気に入りから削除しました");
    } else {
      props.addFavorite(props.ai);
      toast.success("お気に入りに追加しました");
    }
  };
  return (
    <>
      <div className="border-b border-gray-200 mr-5 mt-3">
        <div className="pb-4 md:px-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="relative flex items-start space-x-2 mb-4 sm:mb-0">
            <div className="relative w-16 min-w-fit mr-2">
              <img
                src={props.ai.image.thumb.url}
                alt=""
                className="w-16 h-16 rounded-md object-cover"
              />
            </div>
            <div className="flex flex-col leading-tight">
              <div className="lg:text-xl text-lg mt-1 flex items-start">
                <span className="text-gray-700 font-bold mr-3">
                  {props.ai.name}
                </span>
              </div>
              <p className="mt-1 text-xs text-gray-500">{props.ai.intro}</p>
            </div>
          </div>

          {/* お気に入りボタン */}
          <div className="flex items-center space-x-2">
            <button
              type="button"
              className={`${
                isFavorite() ? "text-red-500 border-red-300" : "text-gray-600"
              } inline-flex items-center justify-center rounded-lg border border-gray-500 w-32 h-12 transition duration-500 ease-in-out hover:opacity-90 focus:outline-none`}
              onClick={handleFavoriteClick}
            >
              {isFavorite() ? (
                <span className="text-gray-600 text-sm ml-1">追加済み</span>
              ) : (
                <span className="text-gray-600 text-sm ml-1">
                  お気に入り
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAiChatHeader;
