import React from "react";
import { FileText } from "lucide-react";
import { UsersAi } from "../../../../interfaces/users_ai";

// TODO: AIのフォームを受け取ってフォームに入力する
export type GenerateLogItemProps = {
  date: string;
  usersAis: UsersAi[];
  onClickSideTitle: (usersAi: UsersAi) => void;
};

const GenerateLogItem: React.FC<GenerateLogItemProps> = (props) => {
  return (
    <>
      <div className="mb-6">
        <span className="bg-gray-400 text-white text-sm font-semibold rounded-full px-2 inline-block">
          {props.date}
        </span>
        <div className="grid gap-2 mt-2">
          {props.usersAis.map((usersAi) => (
            <React.Fragment key={usersAi.id}>
              {/* TOOD: ボタン押下時にフォームに情報を入力できるようにする */}
              <a
                className="text-base cursor-pointer"
                onClick={() => props.onClickSideTitle(usersAi)}
              >
                <div className="flex items-center">
                  <FileText className="text-cwaiblue-600 h-4 w-4 shrink-0" />
                  <div className="ml-1 line-clamp-1">
                    {usersAi.sidebarTitle}
                  </div>
                </div>
              </a>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default GenerateLogItem;
