import React, { useState, useEffect } from "react";
import { Ai } from "../../../../../../interfaces/ai";
import { User } from "../../../../../../interfaces/user";
import NotifyGeneratedText from "../../../../partials/modals/NotifyGeneratedText";

type Props = {
  ai: Ai;
  user?: User;
  message: string;
  addEditorBody?: (s: any) => void;
  openSave?: boolean;
  setOpenSave?: (s: any) => void;
  openUpgrade?: boolean;
  setOpenUpgrade?: (s: any) => void;
  setIsEdit: (b: boolean) => void;
  setMessage?: (s: any) => void;
  setPrevMessage: (s: any) => void;

  // 非公開の生成結果ならば true
  privateFlag: boolean;

  // 公開/非公開切り替えボタンを押した際のコールバック関数
  togglePrivateFlagCallback: (privateFlag: boolean) => void;

  showTogglePrivateFlagButton: boolean
};

import { toast } from "react-toastify";

const TogglePrivateFlagButton: React.FC<{
  privateFlag: boolean,
  callback: (privateFlag: boolean) => void
}> = ({ privateFlag, callback }) => {
  return privateFlag ? (
    <div className="relative group">
      <div className="balloon">生成結果を組織内で公開します</div>
      <button
        className="text-sm text-gray-600 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded px-3 py-1.5"
        onClick={() => callback(false) }
      >
        <span className="hidden lg:inline-block">公開する</span>
      </button>
    </div>
  ) : (
    <div className="relative group">
      <div className="balloon -top-12">
        生成結果を自分以外の<br/>ユーザーに非公開にします
      </div>
      <button
        className="text-sm text-gray-600 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded px-3 py-1.5"
        onClick={() => callback(true) }
      >
        <span className="hidden lg:inline-block">非公開にする</span>
      </button>
    </div>
  );
}

const UserAiChatMessagesActionBtns: React.FC<Props> = (props) => {
  const [openEmail, setOpenEmail] = useState(false);

  // コピーボタン
  // httpsかlocalhostならコピーできるがhttp://localhost.ordermaid.ai/だとコピーできない
  const copyClipboard = () => {
    navigator.clipboard.writeText(props.message);
    toast.success("コピーしました");
  };

  // ダウンロード
  const download = () => {
    const filename = `${props.ai.slug}-${Date.now()}.txt`;
    const blob = new Blob([props.message], { type: "text/plain" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;

    // Append the download link to the DOM, trigger the click event, and remove the link
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <div className="flex text-gray-600">
        {/* ダウンロードボタン */}
        <span className="relative group">
          <span className="balloon">クリックでダウンロード</span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-sm text-gray-600 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded px-3 py-1.5 mr-2"
            onClick={() => download()}
          >
            <span className="hidden lg:inline-block ml-1">ダウンロード</span>
          </button>
        </span>

        {/* コピーボタン */}
        <span className="relative group">
          <span className="balloon">クリックでコピーできます</span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-sm text-gray-600 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded px-3 py-1.5 mr-2"
            onClick={() => copyClipboard()}
          >
            <span className="hidden lg:inline-block ml-1">コピー</span>
          </button>
        </span>

        {/* 保存ボタン */}
        <span className="relative group">
          <span className="balloon">コンテンツを編集</span>
          <button
            data-tooltip-target="tooltip-copy-clipboard"
            className="text-sm text-gray-600 bg-white border border-gray-600 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 rounded px-3 py-1.5 mr-2"
            onClick={() => {
              props.setIsEdit(true);
              props.setPrevMessage(props.message);
              setTimeout(() => {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                });
              }, 10);
            }}
          >
            <span className="hidden lg:inline-block ml-1">編集</span>
          </button>
        </span>
        { props.showTogglePrivateFlagButton && <TogglePrivateFlagButton privateFlag={props.privateFlag} callback={props.togglePrivateFlagCallback} /> }
      </div>
      <NotifyGeneratedText
        email={props.user ? props.user.email : ""}
        text={props.message}
        open={openEmail}
        setOpen={setOpenEmail}
      />
    </>
  );
};

export default UserAiChatMessagesActionBtns;
